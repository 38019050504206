import React, { useState } from "react";
import "./UnsubscribeFeedback.css";
import logo from "../images/darkhorsestocks.png";
import axios from "axios";
import { useLocation } from "react-router-dom";

const URL = process.env.REACT_APP_API_URL;

const UnsubscribedSuccessfully = () => {
  const [isResubscribe, setisResubscribe] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setisResubscribe(true);

    try {
     await axios.delete(`${URL}/resubscribe/${email}`);
     console.log("email",email)
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="container">
      <h1> {isResubscribe ? "Resubscribed" : "Unsubscribed"} successfully</h1>

      {isResubscribe ? (
        <p>Welcome Back!</p>
      ) : (
        <p>We are sorry to see you go!</p>
      )}

      <img src={logo} alt="Envelope Icon" />
      {isResubscribe ? (
        ""
      ) : (
        <form className="feedback-section" onSubmit={handleSubmit}>
          <div className="resubscribe-section">
            <p>Didn't mean to Unsubscribe?</p>
            <button type="submit" className="resubscribe-btn">
              RESUBSCRIBE
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default UnsubscribedSuccessfully;
