import React, { useState } from "react";
import "./UnsubscribeFeedback.css";
import logo from "../images/darkhorsestocks.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const URL = process.env.REACT_APP_API_URL;

const UnsubscribeFeedback = () => {
  const [rating, setRating] = useState("");
  const [unsubscribeReason, setUnsubscribeReason] = useState("");
  const [feedback, setFeedback] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");

  const handleRatingClick = (num) => {
    setRating(num);
  };

  const handleUnsubscribeReasonChange = (event) => {
    setUnsubscribeReason(event.target.value);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${URL}/unsubscribers`, {
        email,
        feedback,
        rating,
        reason: unsubscribeReason,
      });
      // Reset the form
      setRating("");
      setUnsubscribeReason("");
      setFeedback("");
      // Navigate to another page
      navigate(`/unsubscribed-successfully?email=${email}`);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="container">
      <h1>Unsubscribe from emails!</h1>
      <p>We are sorry to see you go!</p>
      <img src={logo} alt="Envelope Icon" />
      <form className="feedback-section" onSubmit={handleSubmit}>
        <h2>Quick Feedback</h2>
        <p>
          Help us know how can we make your experience better by taking this
          short survey. We appreciate your time.
        </p>
        <p>How satisfied are you with our product?</p>
        <div className="rating">
          {["1", "2", "3", "4", "5"].map((num) => (
            <button
              type="button"
              key={num}
              onClick={() => handleRatingClick(num)}
              className={rating === num ? "active" : ""}
            >
              {num}
            </button>
          ))}
        </div>
        <p>Why do you want to unsubscribe?</p>
        <select
          value={unsubscribeReason}
          onChange={handleUnsubscribeReasonChange}
        >
          <option>Choose Option</option>
          <option value="high_frequency_of_emails">
            High frequency of emails
          </option>
          <option value="emails_are_not_relevant">
            Emails are not relevant
          </option>
          <option value="dont_remember_subscribing">
            I don't remember subscribing
          </option>
          <option value="other">Other</option>
        </select>
        <p>How can we make the newsletter experience better?*</p>
        <input
          type="text"
          placeholder="Enter your feedback"
          value={feedback}
          onChange={handleFeedbackChange}
        />
        <button type="submit" className="submit-btn">
          SUBMIT & NEXT
        </button>
      </form>
    </div>
  );
};

export default UnsubscribeFeedback;
