import "./App.css";
import UnsubscribeFeedback from "./components/UnsubscribeFeedback";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UnsubscribedSuccessfully from "./components/UnsubscribedSuccessfully";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/unsubscribe" element={<UnsubscribeFeedback />} />
          <Route
            path="/unsubscribed-successfully"
            element={<UnsubscribedSuccessfully />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
